



















































































































import { Component, Vue } from "vue-property-decorator";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import ProgramCard from "@/components/ProgramCard.vue";

@Component<ProgramCatalogIndex>({
  metaInfo: {
    title: "Program Kataloğu"
  },
  components: {
    Breadcrumbs,
    ProgramCard
  }
})
export default class ProgramCatalogIndex extends Vue {
  breadcrumbs = [
    {
      text: "Ana Sayfa",
      exact: true,
      to: { name: "Home" }
    },
    {
      text: "Program Kataloğu"
    }
  ];

  options = {};
  
  progs = [
    {
      image: require("@/assets/yeminli-sosyal-arabuluculuk.jpg"),
      href: "/program-katalogu/hegem-yeminli-sosyal-arabuluculuk",
      name: "Yeminli Sosyal Arabuluculuk Sertifika Programı",
      price: 470
    },
    {
      image: require("@/assets/siddeti-onleme-ve-insan-haklari-danismanligi.jpg"),
      href: "/program-katalogu/hegem-siddeti-onleme-ve-insan-haklari-danismanligi",
      name: "Şiddeti Önleme ve İnsan Hakları Danışmanlığı Sertifika Programı",
      price: 370
    },
    {
      image: require("@/assets/aile-temelli-arabuluculuk.jpeg"),
      href: "/program-katalogu/hegem-aile-temelli-arabuluculuk",
      name: "Aile Temelli Arabuluculuk Sertifika Programı",
      price: 8500
    },
    {
      image: require("@/assets/kooperatifcilik.jpg"),
      href: "/program-katalogu/kooperatifcilik",
      name: "Kooperatifçilik Eğitim Programı",
      price: 1500
    }
  ];

  loading = false;
  itemsPerPageArray = [10, 20, 40];
  itemsPerPage = 10;
  page = 1;
  search = "";
  filter = {};
  sortBy = "name";
  sortDesc = false;
  keys = [
    { label: "Program Adı", value: "name" },
    { label: "Ücret", value: "price" }
  ];

  get numberOfPages(): number {
    return Math.ceil(this.progs.length / this.itemsPerPage);
  }

  nextPage(): void {
    if (this.page + 1 <= this.numberOfPages) this.page += 1;
  }

  formerPage(): void {
    if (this.page - 1 >= 1) this.page -= 1;
  }

  updateItemsPerPage(x: number): void {
    this.itemsPerPage = x;
  }

  customSort(items: any, index: any, isDesc: any) {
    items.sort((a: any, b: any) => {
      if (index[0] == "startDate" || index [0] == "price") {
        if (isDesc[0]) {
          return b[index] - a[index];
        } else {
          return a[index] - b[index];
        }
      } else {
        if (typeof a[index] !== "undefined") {
          if (!isDesc[0]) {
            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
          } else {
            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
          }
        }
      }
    });

    return items;
  }
}
